import React from 'react'
import Main from '../../layouts/Main'
import {
  WrapperTwoCol,
  ContainerTextContent,
  PageTitle,
  ContactForm,
  ContainerTextContentCentered,
  AllRecommended,
  Tabs,
  Staff,
  Timeline,
  HeroImage
} from '../../components'

const Page = ({ data }) => {
  const { content, id, node_locale: nodeLocale, contentful_id: contentulId } = data.contentfulPage
  return (
    <Main currentId={id} currentLang={nodeLocale} currentPageId={contentulId}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 main-content'>
            {content !== null &&
              <DisplayContent content={content} nodeLocale={nodeLocale} />
            }
          </div>
        </div>
      </div>
    </Main>
  )
}

const types = {
  'contactForm': ContactForm,
  'allRecommended': AllRecommended,
  'ContentfulPageTitle': PageTitle,
  'ContentfulCmpMainContentCentered': ContainerTextContentCentered,
  'ContentfulCmpMainContent': ContainerTextContent,
  'ContentfulCmpTwoCol': WrapperTwoCol,
  'ContentfulCmpTab': Tabs,
  'ContentfulCmpStaff': Staff,
  'ContentfulCmpTimeline': Timeline,
  'ContentfulCmpHeroImage': HeroImage
}
const DisplayContent = ({ content, nodeLocale }) => {
  // Content will be grouped if is a tabs component, to display together.
  // If last content in was a tab and then the current one (if its a tab as well), will
  // be added into the tabs group in the grouped content array
  let lastIsTab = false
  const updatedContent = content.reduce((groupedContent, currentContent) => {
    if (currentContent.__typename === 'ContentfulCmpTab') {
      if (lastIsTab) {
        const lastTabIndex = groupedContent.length - 1
        const grouped = [...groupedContent[lastTabIndex].grouped, { title: currentContent.title, body: currentContent.body }]
        groupedContent[lastTabIndex] = {...groupedContent[lastTabIndex], grouped}
      } else {
        groupedContent.push({...currentContent, grouped: [{ title: currentContent.title, body: currentContent.body }]})
      }
      lastIsTab = true
    } else {
      groupedContent.push(currentContent)
      lastIsTab = false
    }
    return groupedContent
  }, [])

  return (
    <div>
      {updatedContent && updatedContent.map((cont, i) => {
        let DynamicComponent = null

        if (cont.__typename === 'ContentfulSystem') {
          DynamicComponent = types[cont.identifier]
        } else {
          DynamicComponent = types[cont.__typename]
        }

        return (
          DynamicComponent &&
          <DynamicComponent
            key={i}
            content={cont}
            nodeLocale={nodeLocale}
          />
        )
      })}
    </div>
  )
}

export default Page
