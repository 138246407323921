import Cmp from './Page.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      node_locale
      content {
        __typename
        ... on ContentfulCmpTimeline {
          timelineCard {
            date
            title
            text {
              text
            }
            imageGallery {
              file {
                url
              }
            }
            video {
              file {
                url
              }
            }
            cardImage {
              fixedImage: gatsbyImageData(quality: 92, width: 460, height: 300, layout: FIXED, formats: [AUTO, WEBP])
              largeImage: gatsbyImageData(quality: 92, width: 800, layout: CONSTRAINED, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulCmpStaff {
          title
          questionRows {
            question
            answers {
              answers
              childMarkdownRemark {
                html
              }
            }
          }
          allStaffMembers: staffMembers {
            title
            jobTitle
            category {
              uid
              categoryName
            }
          }
        }
        ... on ContentfulSystem {
          identifier
        }        
        ... on ContentfulPageTitle {
          pageTitle
        }
        ... on ContentfulCmpMainContentCentered {
          body {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCmpMainContent {
          title,
          body {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCmpTab {
          title,
          body {
            childMarkdownRemark {
              html
            }
          }
        }        
        ... on ContentfulCmpHeroImage {
          image {
            gatsbyImageData(quality: 92, layout: CONSTRAINED, width: 950, formats: [AUTO, WEBP])
          }
        }
        ... on ContentfulCmpTwoCol {
          content {
            __typename
            ... on ContentfulCmpMainContent {
              body {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulCmpSideImage {
              image {
                gatsbyImageData(quality: 92, layout: CONSTRAINED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
